var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var CX,DX,EX,GX,HX,IX,JX;CX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);DX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
EX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.FX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);GX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
HX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);IX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);JX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.xV($CLJS.hJ,$CLJS.G([$CLJS.wu,$CLJS.kJ,$CLJS.Qt,$CLJS.nL]));$CLJS.BL.o(null,$CLJS.FX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.CL(a);return $CLJS.le(a)?(a=$CLJS.Ez.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.NI,null,$CLJS.RJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.mV($CLJS.FX,$CLJS.OL);
for(var KX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cK,$CLJS.KK],null)),LX=null,MX=0,NX=0;;)if(NX<MX){var OX=LX.$(null,NX);$CLJS.xV(OX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null),$CLJS.Qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.nL],null)]));$CLJS.mV(OX,$CLJS.FX);NX+=1}else{var PX=$CLJS.A(KX);if(PX){var QX=PX;if($CLJS.re(QX)){var RX=$CLJS.$c(QX),Ima=$CLJS.ad(QX),Jma=RX,Kma=$CLJS.E(RX);KX=Ima;LX=Jma;MX=Kma}else{var SX=$CLJS.C(QX);$CLJS.xV(SX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.gq,$CLJS.IL],null),$CLJS.Qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.nL],null)]));$CLJS.mV(SX,$CLJS.FX);KX=$CLJS.D(QX);LX=null;MX=0}NX=0}else break}
for(var TX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DI,$CLJS.XH,$CLJS.$J,$CLJS.vK,$CLJS.fK,$CLJS.CI,$CLJS.RK],null)),UX=null,VX=0,WX=0;;)if(WX<VX){var Lma=UX.$(null,WX);$CLJS.xV(Lma,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)]));WX+=1}else{var XX=$CLJS.A(TX);if(XX){var YX=XX;if($CLJS.re(YX)){var ZX=$CLJS.$c(YX),Mma=$CLJS.ad(YX),Nma=ZX,Oma=$CLJS.E(ZX);TX=Mma;UX=Nma;VX=Oma}else{var Pma=$CLJS.C(YX);$CLJS.xV(Pma,$CLJS.G([$CLJS.wu,
$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)]));TX=$CLJS.D(YX);UX=null;VX=0}WX=0}else break}$CLJS.xV($CLJS.gJ,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.EV],null)]));
for(var $X=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.RK,null,$CLJS.fK,null,$CLJS.vK,null,$CLJS.iK,null,$CLJS.CI,null,$CLJS.XH,null,$CLJS.DI,null,$CLJS.$J,null],null),null)),aY=null,bY=0,cY=0;;)if(cY<bY){var Qma=aY.$(null,cY);$CLJS.xV(Qma,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)]));cY+=1}else{var dY=$CLJS.A($X);if(dY){var eY=dY;if($CLJS.re(eY)){var fY=$CLJS.$c(eY),Rma=$CLJS.ad(eY),Sma=fY,Tma=$CLJS.E(fY);
$X=Rma;aY=Sma;bY=Tma}else{var Uma=$CLJS.C(eY);$CLJS.xV(Uma,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)]));$X=$CLJS.D(eY);aY=null;bY=0}cY=0}else break}$CLJS.iL(JX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ft,$CLJS.uI,$CLJS.pJ,$CLJS.HJ],null));
$CLJS.zV($CLJS.bK,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,JX],null)],null)],null)],null)]));
$CLJS.iL(IX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.tL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ft,new $CLJS.k(null,2,[$CLJS.cv,"valid timezone ID",$CLJS.ev,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ol);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.UL.tz.names()))],null));
$CLJS.zV($CLJS.tI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,IX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,IX],null)],null)],null)],null)]));
$CLJS.mV($CLJS.tI,$CLJS.FX);$CLJS.xV($CLJS.CJ,$CLJS.G([$CLJS.wu,$CLJS.VI]));$CLJS.iL(EX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.NH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NI,$CLJS.RJ],null))}],null)],null));
$CLJS.iL(CX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,EX],null)],null)],null)],null));
$CLJS.tV($CLJS.EI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.cv,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.EI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,CX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.sX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.uX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.nX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.FV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.mB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.wX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.DV],null)],null)],null)],null)],null)],null));
$CLJS.BL.o(null,$CLJS.EI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.mB)?$CLJS.F.h(a,$CLJS.Pj)?$CLJS.RJ:$CLJS.m($CLJS.oV($CLJS.FV,a))?$CLJS.NI:$CLJS.RJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.AX,b))?$CLJS.NI:$CLJS.m($CLJS.Di($CLJS.BX,b))?$CLJS.NI:null:null;if($CLJS.m(a))return a;b=$CLJS.CL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.NH(c,$CLJS.Il)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.iL(HX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.mB],null),$CLJS.Qt],null));
$CLJS.zV($CLJS.qJ,$CLJS.G([$CLJS.wu,$CLJS.RJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,HX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.nL],null)],null)],null)],null)]));
$CLJS.xV($CLJS.YD,$CLJS.G([$CLJS.wu,$CLJS.WK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.mL],null)]));$CLJS.iL(DX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Ft,$CLJS.eJ,$CLJS.ek,$CLJS.Yl,$CLJS.WI,$CLJS.TK,$CLJS.YK,$CLJS.rl,$CLJS.kk,$CLJS.kn,$CLJS.Pk,$CLJS.HI],null));$CLJS.iL(GX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ft,$CLJS.uI,$CLJS.pJ,$CLJS.HJ],null));
$CLJS.zV($CLJS.nK,$CLJS.G([$CLJS.wu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,DX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,GX],null)],null)],
null)],null)]));