var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var r9,t9,s9,Hva,y9,z9,Iva,Jva,Kva,Lva,u9,Mva,D9,Nva,Ova,Pva;r9=function(a,b){return $CLJS.m(a)?$CLJS.Ua(a,b):null};t9=function(a,b){return s9(a,b,$CLJS.P)};
s9=function(a,b,c){c=$CLJS.Qf(c);c=$CLJS.M.j(c,u9,!0);if($CLJS.oe(b))return $CLJS.oh.j($CLJS.P,a,b);c=$CLJS.m(c)?function(){var d=r9(b,"_plainObject");d=null==d?null:$CLJS.hF(d);return null==d?null:$CLJS.Rf(d)}():null;return $CLJS.m(c)?$CLJS.oh.j($CLJS.P,a,c):$CLJS.oh.j($CLJS.P,$CLJS.Im.j($CLJS.rg.g(function(d){return new $CLJS.S(null,2,5,$CLJS.T,[d,r9(b,d)],null)}),$CLJS.Lm.g(function(d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.F.h($CLJS.Na(d),"function")}),a),$CLJS.Ta(b))};
Hva=function(a){var b=v9.g(a),c=w9.g(a);return $CLJS.Im.j($CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Oi.g((0,$CLJS.nG)(e)),d],null)}),$CLJS.je(b)?$CLJS.Ye:$CLJS.Lm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return $CLJS.Ie(b,e)}),$CLJS.Gb(c)?$CLJS.Ye:$CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[e,c.h?c.h(e,d):c.call(null,e,d)],null)}))};
y9=function(a,b){var c=Hva(a),d=x9.g(a);return function(e){try{var f=$CLJS.U.j(s9(c,e,b),$CLJS.uz,d),g=$CLJS.XF($CLJS.VD);$CLJS.m($CLJS.dG("metabase.lib.js.metadata",g))&&$CLJS.eG("metabase.lib.js.metadata",g,$CLJS.V_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Ek.g(f),$CLJS.W_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.XF($CLJS.Vu),$CLJS.m($CLJS.dG("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.eG("metabase.lib.js.metadata",g,$CLJS.V_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.aj.l($CLJS.G([e])),$CLJS.RZ(f)])),f):$CLJS.eG("metabase.lib.js.metadata",g,$CLJS.V_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.aj.l($CLJS.G([e])),$CLJS.RZ(f)])),null)),null;throw l;}}};z9=function(a){return $CLJS.Mm.h(y9($CLJS.EQ,$CLJS.P),a)};Iva=new $CLJS.N(null,"plain-object","plain-object",-943264281);$CLJS.A9=new $CLJS.N(null,"cards","cards",169174038);Jva=new $CLJS.N(null,"db","db",993250759);$CLJS.B9=new $CLJS.N(null,"metrics","metrics",394093469);
Kva=new $CLJS.N(null,"fks","fks",2041987076);$CLJS.C9=new $CLJS.N(null,"segments","segments",1937535949);Lva=new $CLJS.N(null,"dimensions","dimensions",-254818097);u9=new $CLJS.N(null,"use-plain-object?","use-plain-object?",-1174595475);Mva=new $CLJS.N(null,"metadata","metadata",1799301597);D9=new $CLJS.N(null,"dimension-options","dimension-options",2122379680);Nva=new $CLJS.N(null,"schema-name","schema-name",1666725119);Ova=new $CLJS.N(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
Pva=new $CLJS.N(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.E9=new $CLJS.N(null,"databases","databases",2040134125);var v9,Qva=$CLJS.cj.g($CLJS.P),Rva=$CLJS.cj.g($CLJS.P),Sva=$CLJS.cj.g($CLJS.P),Tva=$CLJS.cj.g($CLJS.P),Uva=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null)),G9,ewa,fwa,gwa,hwa,iwa;v9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","excluded-keys"),$CLJS.Oi,$CLJS.Pj,Uva,Qva,Rva,Sva,Tva);v9.o(null,$CLJS.Pj,function(){return null});
var w9,Vva=$CLJS.cj.g($CLJS.P),Wva=$CLJS.cj.g($CLJS.P),Xva=$CLJS.cj.g($CLJS.P),Yva=$CLJS.cj.g($CLJS.P),Zva=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));w9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Oi,$CLJS.Pj,Zva,Vva,Wva,Xva,Yva);w9.o(null,$CLJS.Pj,function(){return null});var x9,$va=$CLJS.cj.g($CLJS.P),awa=$CLJS.cj.g($CLJS.P),bwa=$CLJS.cj.g($CLJS.P),cwa=$CLJS.cj.g($CLJS.P),dwa=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
x9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","lib-type"),$CLJS.Oi,$CLJS.Pj,dwa,$va,awa,bwa,cwa);$CLJS.F9=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Oi.g(f)},$CLJS.Pj,e,a,b,c,d)}();ewa=$CLJS.cj.g($CLJS.P);fwa=$CLJS.cj.g($CLJS.P);gwa=$CLJS.cj.g($CLJS.P);hwa=$CLJS.cj.g($CLJS.P);
iwa=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));G9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Oi,$CLJS.Pj,iwa,ewa,fwa,gwa,hwa);$CLJS.F9.o(null,$CLJS.Pj,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)}),r9(b,G9.g(a)))});x9.o(null,$CLJS.MQ,function(){return $CLJS.Ooa});
v9.o(null,$CLJS.MQ,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.xO,null,$CLJS.h0,null],null),null)});w9.o(null,$CLJS.MQ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "dbms-version":return $CLJS.gF(b,$CLJS.G([$CLJS.dk,!0]));case "features":return $CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Oi),b);case "native-permissions":return $CLJS.Oi.g(b);default:return b}}});G9.o(null,$CLJS.MQ,function(){return"databases"});x9.o(null,$CLJS.j0,function(){return $CLJS.f0});
v9.o(null,$CLJS.j0,function(){return new $CLJS.ni(null,new $CLJS.k(null,5,[D9,null,$CLJS.xO,null,$CLJS.C9,null,$CLJS.MQ,null,$CLJS.B9,null],null),null)});w9.o(null,$CLJS.j0,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "entity-type":return $CLJS.Oi.g(b);case "field-order":return $CLJS.Oi.g(b);case "initial-sync-status":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);default:return b}}});
$CLJS.F9.o(null,$CLJS.j0,function(a,b){var c=y9(a,$CLJS.P);return t9($CLJS.Im.h($CLJS.Lm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)})),r9(b,"tables"))});x9.o(null,$CLJS.EQ,function(){return $CLJS.gW});
v9.o(null,$CLJS.EQ,function(){return new $CLJS.ni(null,new $CLJS.k(null,7,[D9,null,$CLJS.j0,null,Lva,null,Pva,null,$CLJS.MQ,null,Ova,null,$CLJS.B9,null],null),null)});
w9.o(null,$CLJS.EQ,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "base-type":return $CLJS.Oi.g(b);case "coercion-strategy":return $CLJS.Oi.g(b);case "effective-type":return $CLJS.Oi.g(b);case "fingerprint":return $CLJS.oe(b)?$CLJS.WZ(b):$CLJS.gF(b,$CLJS.G([$CLJS.dk,!0]));case "has-field-values":return $CLJS.Oi.g(b);case "semantic-type":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);case "id":return $CLJS.qe(b)?$CLJS.Zd(b):b;default:return b}}});
$CLJS.F9.o(null,$CLJS.EQ,function(a,b){var c=y9(a,$CLJS.P),d=r9(b,"fields");return t9($CLJS.xn(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null);e=function(){var l=$CLJS.pL(f);if($CLJS.m(l))return l;l=$CLJS.Di(/card__\d+:(\d+)/,f);if($CLJS.m(l)){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=null==r9(d,l);return n?$CLJS.pL(l):n}return null}();return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[e,new $CLJS.Yi(function(){return c.g?c.g(g):c.call(null,g)})],null):null}),d)});x9.o(null,$CLJS.OI,function(){return $CLJS.k0});
v9.o(null,$CLJS.OI,function(){return new $CLJS.ni(null,new $CLJS.k(null,11,[D9,null,$CLJS.$l,null,Kva,null,$CLJS.j0,null,Jva,null,Iva,null,$CLJS.C9,null,$CLJS.MQ,null,Mva,null,$CLJS.B9,null,Nva,null],null),null)});
w9.o(null,$CLJS.OI,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "result-metadata":return $CLJS.m($CLJS.Km.h($CLJS.ne,$CLJS.Db)(b))?z9(b):$CLJS.gF(b,$CLJS.G([$CLJS.dk,!0]));case "fields":return z9(b);case "visibility-type":return $CLJS.Oi.g(b);case "dataset-query":return $CLJS.gF(b,$CLJS.G([$CLJS.dk,!0]));default:return b}}});
$CLJS.F9.o(null,$CLJS.OI,function(a,b){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.Yi(function(){var d=y9($CLJS.OI,new $CLJS.k(null,1,[u9,!1],null)),e=y9($CLJS.OI,$CLJS.P),f=$CLJS.cn.l;d=$CLJS.U.j(d(r9(r9(b,"tables"),["card__",$CLJS.p.g(c)].join(""))),$CLJS.Ek,c);var g=r9(r9(b,"questions"),$CLJS.p.g(c));var l=r9(g,"_card");g=$CLJS.m(l)?l:g;e=e.g?e.g(g):e.call(null,g);return f.call($CLJS.cn,$CLJS.G([d,e]))})],null)}),$CLJS.oh.j($CLJS.oh.j($CLJS.oi,
$CLJS.xn($CLJS.c0),$CLJS.Ta(r9(b,"tables"))),$CLJS.rg.g($CLJS.pL),$CLJS.Ta(r9(b,"questions"))))});x9.o(null,$CLJS.aQ,function(){return $CLJS.hW});v9.o(null,$CLJS.aQ,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.j0,null,$CLJS.MQ,null],null),null)});w9.o(null,$CLJS.aQ,function(){return function(a,b){return b}});G9.o(null,$CLJS.aQ,function(){return"metrics"});x9.o(null,$CLJS.mI,function(){return $CLJS.e0});
v9.o(null,$CLJS.mI,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.j0,null,$CLJS.MQ,null],null),null)});w9.o(null,$CLJS.mI,function(){return function(a,b){return b}});G9.o(null,$CLJS.mI,function(){return"segments"});