var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var B6=function(a){var b=$CLJS.cn.l;var c=$CLJS.Qf(a);var d=$CLJS.M.h(c,$CLJS.u6);c=$CLJS.M.h(c,$CLJS.v6);if($CLJS.m($CLJS.m(d)?$CLJS.F.h(c,"currency"):d)){c=$CLJS.p6.g;d=$CLJS.Oi.g(d);var e=$CLJS.r($CLJS.m6);d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.p6,d);c=new $CLJS.k(null,2,[$CLJS.t6,c,$CLJS.w6,c],null)}else c=new $CLJS.k(null,1,[$CLJS.w6,2],null);b=b.call($CLJS.cn,$CLJS.G([c,a]));return $CLJS.m($CLJS.x6.g(a))?$CLJS.U.l($CLJS.Am.h(b,$CLJS.x6),$CLJS.w6,$CLJS.x6.g(a),$CLJS.G([$CLJS.t6,
$CLJS.x6.g(a)])):b},C6=function(a,b){if(null!=a&&null!=a.Ff)a=a.Ff(a,b);else{var c=C6[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=C6._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("NumberFormatter.format-number-basic",a);}return a},csa=function(a,b){var c=$CLJS.gF(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.dk,!0],null)]));a=$CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Uk.g(d),"exponentMinusSign")},c))?c:function(){var d=$CLJS.Bi(function(f){return $CLJS.ki.h($CLJS.Uk.g(f),
"exponentInteger")},c),e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.qg.l(e,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.Uk,"exponentPlusSign",$CLJS.ol,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.R.h($CLJS.p,$CLJS.rg.h(function(d){var e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.Uk);e=$CLJS.M.h(e,$CLJS.ol);switch(d){case "exponentSeparator":return"e";default:return e}},a))},dsa=function(a,b){switch($CLJS.v6.g(b)){case "scientific":return function(c){return csa(a,c)};default:return function(c){return a.format(c)}}},
D6=function(a,b,c,d,e){this.options=a;this.rh=b;this.th=c;this.Ue=d;this.cj=e;this.C=393216;this.K=0},esa=function(a,b){return $CLJS.R.h($CLJS.p,function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.be(g,v),y=$CLJS.Qf(x);x=$CLJS.M.h(y,$CLJS.Uk);y=$CLJS.M.h(y,$CLJS.ol);var B=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],
null),null);B=B.g?B.g(x):B.call(null,x);$CLJS.m(B)&&(x=$CLJS.F.h(x,"integer")?b:y,n.add(x));v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);q=$CLJS.Qf(q);var u=$CLJS.M.h(q,$CLJS.Uk);q=$CLJS.M.h(q,$CLJS.ol);if($CLJS.m(function(){var v=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.nf($CLJS.F.h(u,"integer")?b:q,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,
null)}($CLJS.gF(a.rh.formatToParts(1),$CLJS.G([$CLJS.dk,!0])))}())},E6=function(a){var b=$CLJS.F.h($CLJS.v6.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.fn($CLJS.$F($CLJS.Hb,$CLJS.Sh([$CLJS.$ra,$CLJS.Sra,$CLJS.Vra,$CLJS.Xra,$CLJS.bsa,$CLJS.u6,$CLJS.Wra,$CLJS.Yra,$CLJS.Tra,$CLJS.Ura],[$CLJS.w6.h(a,b),$CLJS.z6.g(a),$CLJS.y6.g(a),$CLJS.Zra.g(a),$CLJS.F.h($CLJS.v6.g(a),"scientific")?null:$CLJS.v6.h(a,"decimal"),$CLJS.u6.g(a),!0,$CLJS.t6.h(a,b),$CLJS.F.h($CLJS.v6.g(a),"scientific")?"scientific":
null,$CLJS.A6.g(a)]))));var c=$CLJS.z6.g(a);var d=$CLJS.u6.g(a);c=$CLJS.m(d)?null==c||$CLJS.F.h(c,"symbol"):d;d=dsa(b,a);return new D6(a,b,c,d,$CLJS.P)},F6=function(a,b){return E6(B6(b)).Ff(null,a)},H6=function(a,b){var c=$CLJS.Am.l(b,$CLJS.MG,$CLJS.G([$CLJS.v6])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.G6.h?$CLJS.G6.h(a,c):$CLJS.G6.call(null,a,c);b=$CLJS.C($CLJS.ug(function(f){return d>=$CLJS.C(f)},fsa));var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return[$CLJS.p.g(function(){var f=
a/e,g=$CLJS.cn.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.t6,1,$CLJS.w6,1],null)]));return $CLJS.G6.h?$CLJS.G6.h(f,g):$CLJS.G6.call(null,f,g)}()),$CLJS.p.g(b)].join("")},gsa=function(a,b){var c=B6(b);if($CLJS.m(I6.g(c)))b=I6.g(c);else if($CLJS.Gb($CLJS.x6.g(c))&&$CLJS.Gb($CLJS.t6.g(c))&&$CLJS.ki.h($CLJS.v6.g(c),"currency")&&Math.abs(a)<($CLJS.F.h($CLJS.v6.g(c),"percent")?.01:1)){b=$CLJS.U.j;var d=$CLJS.Am.h(c,$CLJS.w6);c=$CLJS.A6.h(c,0);b=E6(b.call($CLJS.U,d,$CLJS.y6,2>c?2:c))}else b=E6(c);return C6(b,
a)};D6.prototype.O=function(a,b){return new D6(this.options,this.rh,this.th,this.Ue,b)};D6.prototype.N=function(){return this.cj};
D6.prototype.Ff=function(a,b){a=this.Ue.g?this.Ue.g(b):this.Ue.call(null,b);var c=$CLJS.asa.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.ki.h(c,".,"):c)&&(b=$CLJS.C(c),c=$CLJS.Zd(c),a=$CLJS.Mu(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.th)&&(b=$CLJS.u6.g(this.options),c=$CLJS.n6(b),a=$CLJS.Mu($CLJS.Mu(a,[$CLJS.ui(b)," "].join(""),c),$CLJS.ui(b),c));return a};
var I6=new $CLJS.N(null,"number-formatter","number-formatter",929788393),J6=new $CLJS.N(null,"scale","scale",-230427353),K6=new $CLJS.N(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),hsa=new $CLJS.N(null,"scientific","scientific",316285837);var L6=new $CLJS.k(null,1,[$CLJS.z6,"symbol"],null),isa=$CLJS.fn(L6),fsa=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[1E12,"T"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E9,"B"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E6,"M"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E3,"k"],null)],null),M6=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Qf(g);return $CLJS.M.h(f,$CLJS.v6)},$CLJS.Pj,e,a,b,c,d)}();M6.o(null,$CLJS.Pj,function(a,b){return H6(a,b)});M6.o(null,"percent",function(a,b){return[$CLJS.p.g(H6(100*a,b)),"%"].join("")});M6.o(null,"currency",function(a,b){b=$CLJS.cn.l($CLJS.G([b,L6]));var c=E6(b);return 1E3>Math.abs(a)?c.Ff(null,a):esa(c,H6(a,b))});M6.o(null,"scientific",function(a,b){return F6(a,$CLJS.cn.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.w6,1,$CLJS.t6,1],null)])))});
$CLJS.G6=function G6(a,b){b=$CLJS.oG(b);var d=$CLJS.Qf(b);b=$CLJS.M.h(d,$CLJS.MG);var e=$CLJS.M.h(d,K6),f=$CLJS.M.h(d,$CLJS.v6),g=$CLJS.M.h(d,J6);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.Am.h(d,J6),G6.h?G6.h(b,f):G6.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.U.j(d,K6,!1);return G6.h?G6.h(l,n):G6.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?M6.h(a,B6($CLJS.Am.h(d,$CLJS.MG))):$CLJS.F.h($CLJS.Oi.g(f),hsa)?F6(a,d):gsa(a,d)};
module.exports={compact_currency_options_js:isa,format_number:$CLJS.G6};