var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ioa,Z_,Joa,Koa,Moa,Loa;$CLJS.V_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};Ioa=function(a){var b=new $CLJS.rb,c=$CLJS.Rz;$CLJS.Rz=new $CLJS.gd(b);try{var d=$CLJS.Rz,e=$CLJS.Gb($CLJS.ZA(d)),f=$CLJS.Rz;$CLJS.Rz=e?$CLJS.QA(d):d;try{d=$CLJS.aB;$CLJS.aB=!0;try{$CLJS.cB(a)}finally{$CLJS.aB=d}$CLJS.F.h(0,$CLJS.Yz($CLJS.Rz,$CLJS.$z))||$CLJS.Qc($CLJS.Rz,"\n");$CLJS.Xz()}finally{$CLJS.Rz=f}$CLJS.Nz($CLJS.p.g(b))}finally{$CLJS.Rz=c}};
$CLJS.W_=function(a){var b=new $CLJS.rb,c=$CLJS.Ab;$CLJS.Ab=function(d){return b.append(d)};try{Ioa(a)}finally{$CLJS.Ab=c}return $CLJS.p.g(b)};$CLJS.X_=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.Zd(a))&&$CLJS.Ie($CLJS.Zd(a),$CLJS.HG)};$CLJS.Y_=function(a){return $CLJS.X_(a)?$CLJS.Iu(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.ML],null)):null};
Z_=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Nm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Lj)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.QN(d,$CLJS.Pf([b,c])):d};Joa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZP,$CLJS.lR],null));b=$_.g?$_.g(b):$_.call(null,b);return $CLJS.eW($CLJS.U.l(Z_($CLJS.Am.l(a,$CLJS.ZP,$CLJS.G([$CLJS.lR])),$CLJS.aS,$CLJS.iZ),$CLJS.uz,$CLJS.aZ,$CLJS.G([$CLJS.fZ,b])))};
Koa=function(a){return $CLJS.Mm.h(Joa,a)};Moa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Nm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.a0,a],null):a,$CLJS.a0,function(b){return $CLJS.Mm.h(function(c){return $CLJS.U.j($CLJS.bG(c,$CLJS.nG),$CLJS.uz,$CLJS.gW)},b)}),$CLJS.uz,Loa):null};
$CLJS.b0=function(a){if($CLJS.F.h($CLJS.uz.g(a),$CLJS.KZ))return a;var b=$CLJS.Uk.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.cn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.uz,$CLJS.KZ,$CLJS.fZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.cn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.uz,$CLJS.GZ],null),$CLJS.QN($CLJS.UQ.g(a),new $CLJS.k(null,1,[$CLJS.vO,$CLJS.UQ],null))]))],null)],null),$CLJS.Am.l(a,$CLJS.Uk,$CLJS.G([$CLJS.UQ]))]));case "query":return $CLJS.cn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.uz,$CLJS.KZ,$CLJS.fZ,$_($CLJS.vO.g(a))],null),$CLJS.Am.l(a,$CLJS.Uk,$CLJS.G([$CLJS.vO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.c0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.pL(a)):null};$CLJS.d0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.e0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.f0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.g0=new $CLJS.N(null,"stage-number","stage-number",-1752729638);$CLJS.Noa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.h0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.i0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.a0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.Ooa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.j0=new $CLJS.N(null,"table","table",-564943036);
$CLJS.k0=new $CLJS.N("metadata","card","metadata/card",-1039333889);Loa=new $CLJS.N("metadata","results","metadata/results",330329298);var $_;
$_=function $_(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.lR),d=$CLJS.M.h(a,$CLJS.OP);c=$CLJS.m(c)?$_.g?$_.g(c):$_.call(null,c):$CLJS.Lg;d=Moa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Ou(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.mZ],null),d):c;d=$CLJS.m($CLJS.UQ.g(a))?$CLJS.GZ:$CLJS.AZ;a=$CLJS.cn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.uz,d],null),$CLJS.Am.l(a,$CLJS.lR,$CLJS.G([$CLJS.OP]))]));a=$CLJS.A($CLJS.VO.g(a))?$CLJS.Nm.j(a,$CLJS.VO,Koa):a;a=Z_(a,$CLJS.JO,$CLJS.LZ);return $CLJS.kf.h(c,a)};
$CLJS.l0=RegExp(" id$","i");