var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var S1,Gpa,T1,U1,Hpa,Ipa,V1,W1,Jpa,Kpa,Lpa,Mpa,a2,Opa,Ppa,Qpa,Rpa,d2,e2,f2,g2,i2,Tpa,j2,k2,Upa,X1,Z1;S1=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Gpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.j?$CLJS.Uk.j(g,l,n):$CLJS.Uk.call(null,g,l,n),$CLJS.ln.j?$CLJS.ln.j(g,l,n):$CLJS.ln.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.h?$CLJS.Uk.h(g,l):$CLJS.Uk.call(null,g,l),$CLJS.ln.h?$CLJS.ln.h(g,l):$CLJS.ln.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.g?$CLJS.Uk.g(g):$CLJS.Uk.call(null,g),$CLJS.ln.g?$CLJS.ln.g(g):$CLJS.ln.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.A?$CLJS.Uk.A():$CLJS.Uk.call(null),$CLJS.ln.A?$CLJS.ln.A():$CLJS.ln.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Uk,n,q,u,v),$CLJS.R.R($CLJS.ln,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};T1=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};U1=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Hpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.NP,b),$CLJS.uu(2,2,c))};
Ipa=function(){var a=$CLJS.Pp.h($CLJS.OZ,null),b=$CLJS.oy(a,$CLJS.Oaa,function(c){return $CLJS.Po(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.$l,a,$CLJS.ol,f,$CLJS.cu,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
V1=function(a){return Ipa()(a,$CLJS.Lg,$CLJS.Lg)};W1=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Wr($CLJS.$l.g(a));if($CLJS.m(b)){var e=S1($CLJS.ev.g(b),c);$CLJS.m(e)?(e=$CLJS.$r(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:S1($CLJS.cv.g(b),c)}return null};
Jpa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.$l),d=$CLJS.M.h(a,$CLJS.Uk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.cu,$CLJS.oba),f=$CLJS.M.j(b,$CLJS.jba,!0),g=$CLJS.M.h(b,$CLJS.nv),l=$CLJS.M.j(b,$CLJS.lv,$CLJS.jv),n=W1(a,$CLJS.ls.g(c),g,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.ru.g(c),g,b);if($CLJS.m(n))return n;n=W1(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=W1(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.ls.g(c),
l,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.ru.g(c),l,b);if($CLJS.m(n))return n;d=W1(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=W1(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?W1(a,e.g?e.g($CLJS.hv):e.call(null,$CLJS.hv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?W1(a,e.g?e.g($CLJS.hv):e.call(null,$CLJS.hv),l,b):f};
Kpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.ln.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.$l),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.nv);l=$CLJS.M.j(l,$CLJS.lv,$CLJS.jv);g=$CLJS.ls.g(g);f=S1($CLJS.kv.g(g),f);f=$CLJS.m(f)?f:S1($CLJS.kv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Jpa(b,c)],null)};
Lpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ol);a=$CLJS.M.h(b,$CLJS.cu);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.kba,$CLJS.wk),f=$CLJS.M.j(d,$CLJS.mba,Kpa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.wk,n);l=e.g?e.g(l):e.call(null,l);return X1(g,c,q,l)},null,a):null};Mpa=function(a){return $CLJS.qg.h($CLJS.tZ(a),$CLJS.uZ(a))};
$CLJS.Y1=function(a,b){return $CLJS.Ou($CLJS.X_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.oL()),$CLJS.jk,$CLJS.CL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.ML],null),b)};$CLJS.$1=function(){return $CLJS.Im.h(Z1,$CLJS.k_($CLJS.G([$CLJS.l_,$CLJS.jG,$CLJS.m_,function(a,b){return Z1.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
a2=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Yt,null,$CLJS.pn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Ku(2,c):$CLJS.Ku(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?U1(2,c):U1(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.K0.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Lm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.$F($CLJS.Hb,l):l}):$CLJS.Am.h(a,f)};
Opa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return a2(b,Npa,d)},a,Mpa(a))};
Ppa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.fZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Gpa(),$CLJS.ug($CLJS.Im.j(b2,$CLJS.C,$CLJS.ln),$CLJS.cu.g(V1(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=a2(d,f,g),n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.convert",n))){var q=$CLJS.NG.l($CLJS.G([$CLJS.SE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.NG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Lpa(V1(d));$CLJS.eG("metabase.lib.convert",n,
$CLJS.V_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.SE,y.call(x,$CLJS.G([B]))])),$CLJS.NG.l($CLJS.G([$CLJS.HE,$CLJS.W_($CLJS.C($CLJS.Az(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Opa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.fZ.g(a))-1))return a;b+=1}else a=$CLJS.Nm.R(a,$CLJS.fZ,$CLJS.U,b,d)}};
Qpa=function(a){var b=$CLJS.$1();return $CLJS.Mm.h(function(c){return $CLJS.F.h($CLJS.wP.g(c),"__join")?$CLJS.Nm.j(c,$CLJS.wP,b):c},a)};Rpa=function(a){return"string"===typeof $CLJS.ZP.g(a)?$CLJS.Am.h($CLJS.U.j(a,$CLJS.vZ,$CLJS.c0($CLJS.ZP.g(a))),$CLJS.ZP):a};d2=function(a,b){var c=$CLJS.M.j(a,b,c2);if($CLJS.F.h(c,c2))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Spa,a,$CLJS.Yu,b],null));return c};
e2=function(){return $CLJS.Lm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};f2=function(a){return $CLJS.oh.j($CLJS.P,e2(),a)};g2=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Im.h(e2(),$CLJS.Lm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.jk)})),a))};
i2=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.h2),c);a=$CLJS.F.h(a,$CLJS.YI)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(g2(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oO,a,b],null):a};Tpa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Im.h($CLJS.rg.g(function(b){return $CLJS.bG(b,$CLJS.SG)}),$CLJS.rg.g($CLJS.h2)),$CLJS.a0.g(a))};
j2=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.fZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.h2.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.lR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.OP,Tpa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.mZ.g(c)],null)},null,a));return $CLJS.m($CLJS.UQ.g(a))?$CLJS.QN(a,new $CLJS.k(null,1,[$CLJS.UQ,$CLJS.vO],null)):a};
k2=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.u0.j(a,b,$CLJS.Im.h($CLJS.h2,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.u0.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.h2),e)}):d;return $CLJS.QN(a,$CLJS.Pf([b,c]))};Upa=function(a){var b=$CLJS.vZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Am.h(a,$CLJS.vZ),$CLJS.ZP,["card__",$CLJS.p.g(b)].join("")):a};
X1=function X1(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=T1(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.iv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=T1(f,c),d=X1.D?X1.D(b,l,g,d):X1.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lba],null),X1.D?X1.D(f,b,c,d):X1.call(null,f,b,c,d)):$CLJS.m($CLJS.iv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.iv,!0],null))};Z1=function Z1(a){switch(arguments.length){case 1:return Z1.g(arguments[0]);case 2:return Z1.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
Z1.g=function(a){return Z1.h(a,60)};Z1.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.QZ.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};Z1.v=2;
var c2=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Npa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),l2=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),m2=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),Spa=new $CLJS.N(null,"m","m",1632677161),n2=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var o2,p2,b2,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa,cqa,dqa;o2=$CLJS.P;p2=$CLJS.P;b2=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.LZ,null,$CLJS.xO,null,$CLJS.VO,null,$CLJS.PQ,null,$CLJS.GP,null,$CLJS.ZR,null,$CLJS.NP,null],null),null);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.cj.g($CLJS.P);Ypa=$CLJS.cj.g($CLJS.P);Zpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Ok,$CLJS.CH],null),$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.q2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.BH,$CLJS.Pj,Zpa,Vpa,Wpa,Xpa,Ypa);$CLJS.q2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.eW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.q2),d))}else b=a;return b});$CLJS.q2.o(null,$CLJS.KZ,function(a){return a});
$CLJS.q2.o(null,$CLJS.AZ,function(a){var b=$CLJS.q2.g($CLJS.NP.g(a)),c=$CLJS.Rf($CLJS.Mm.h(function(q){var u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);return $CLJS.Y1($CLJS.q2.g(q),u)},$CLJS.PQ.g(a))),d=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[q,u],null)}),b),e=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.HG);return new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null)}),b),f=p2,g=o2;p2=d;o2=e;try{var l=Hpa(Rpa(a),b,$CLJS.G([$CLJS.PQ,c])),n=$CLJS.Sb(function(q,u){return $CLJS.Gb($CLJS.M.h(q,u))?q:$CLJS.Nm.j(q,u,$CLJS.q2)},l,$CLJS.Bm.l(b2,$CLJS.NP,$CLJS.G([$CLJS.PQ])));return $CLJS.m($CLJS.VO.g(n))?$CLJS.Nm.j(n,$CLJS.VO,Qpa):n}finally{o2=g,p2=f}});
$CLJS.q2.o(null,$CLJS.aZ,function(a){a=$CLJS.Nm.j($CLJS.Nm.j(a,$CLJS.iZ,$CLJS.q2),$CLJS.fZ,$CLJS.q2);var b=$CLJS.m($CLJS.xO.g(a))?$CLJS.Nm.j(a,$CLJS.xO,function(c){return $CLJS.xe(c)?$CLJS.Mm.h($CLJS.q2,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.wP.g(a))?$CLJS.U.j(b,$CLJS.wP,"__join"):b});$CLJS.q2.o(null,$CLJS.wG,function(a){return $CLJS.Mm.h($CLJS.q2,a)});
$CLJS.q2.o(null,$CLJS.yG,function(a){return $CLJS.m($CLJS.Uk.g(a))?Ppa($CLJS.Nm.j($CLJS.b0(a),$CLJS.fZ,function(b){return $CLJS.Mm.h($CLJS.q2,b)})):$CLJS.Ju(a,$CLJS.q2)});$CLJS.q2.o(null,$CLJS.EQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,a,b],null))});
$CLJS.q2.o(null,$CLJS.ol,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.QN(a,new $CLJS.k(null,3,[$CLJS.sJ,$CLJS.CG,$CLJS.CP,$CLJS.Hl,$CLJS.gS,$CLJS.vL],null));var c=$CLJS.U.j;var d=$CLJS.jk.g(a);$CLJS.m(d)||(d=$CLJS.CG.g(a),d=$CLJS.m(d)?d:$CLJS.CL(b));a=c.call($CLJS.U,a,$CLJS.jk,d);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,a,b],null))});
$CLJS.q2.o(null,$CLJS.YI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YI,$CLJS.Am.h(c,$CLJS.Pj),$CLJS.Mm.h($CLJS.q2,b)],null);b=$CLJS.eW(b);return null!=a?$CLJS.kf.h(b,$CLJS.q2.g(a)):b});$CLJS.q2.o(null,$CLJS.zG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.q2.o(null,$CLJS.NP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,d2(p2,c)],null))});$CLJS.q2.o(null,$CLJS.oO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.q2.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.cn.l($CLJS.G([c,a]))],null),d)});$pa=$CLJS.cj.g($CLJS.P);aqa=$CLJS.cj.g($CLJS.P);bqa=$CLJS.cj.g($CLJS.P);
cqa=$CLJS.cj.g($CLJS.P);dqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Ok,$CLJS.CH],null),$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.h2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.BH,$CLJS.Pj,dqa,$pa,aqa,bqa,cqa);
$CLJS.h2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.h2,d);a=g2(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.h2,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Ju(f2(a),$CLJS.h2):
a;return a});for(var r2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[n2,m2],null)),s2=null,t2=0,u2=0;;)if(u2<t2){var eqa=s2.$(null,u2);$CLJS.mV(eqa,l2);u2+=1}else{var v2=$CLJS.A(r2);if(v2){var w2=v2;if($CLJS.re(w2)){var x2=$CLJS.$c(w2),fqa=$CLJS.ad(w2),gqa=x2,hqa=$CLJS.E(x2);r2=fqa;s2=gqa;t2=hqa}else{var iqa=$CLJS.C(w2);$CLJS.mV(iqa,l2);r2=$CLJS.D(w2);s2=null;t2=0}u2=0}else break}
for(var y2=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.kD,$CLJS.BJ,$CLJS.dK,$CLJS.LI,$CLJS.Vl,$CLJS.tK,$CLJS.ok,$CLJS.lJ,$CLJS.XJ,$CLJS.yK,$CLJS.rK,$CLJS.MK],null)),z2=null,A2=0,B2=0;;)if(B2<A2){var jqa=z2.$(null,B2);$CLJS.mV(jqa,n2);B2+=1}else{var C2=$CLJS.A(y2);if(C2){var D2=C2;if($CLJS.re(D2)){var E2=$CLJS.$c(D2),kqa=$CLJS.ad(D2),lqa=E2,mqa=$CLJS.E(E2);y2=kqa;z2=lqa;A2=mqa}else{var nqa=$CLJS.C(D2);$CLJS.mV(nqa,n2);y2=$CLJS.D(D2);z2=null;A2=0}B2=0}else break}
for(var F2=$CLJS.A($CLJS.Hg([$CLJS.im,$CLJS.wu,$CLJS.jm,$CLJS.OK,$CLJS.YI,$CLJS.PJ,$CLJS.sI,$CLJS.QK,$CLJS.YH,$CLJS.nJ,$CLJS.iI,$CLJS.FJ,$CLJS.UJ,$CLJS.lI,$CLJS.hJ,$CLJS.qJ,$CLJS.YD,$CLJS.EI,$CLJS.CJ,$CLJS.tI,$CLJS.bK,$CLJS.DI,$CLJS.XH,$CLJS.$J,$CLJS.vK,$CLJS.fK,$CLJS.CI,$CLJS.RK,$CLJS.cK,$CLJS.KK,$CLJS.nI,$CLJS.$H,$CLJS.UK,$CLJS.XW,$CLJS.NJ,$CLJS.iD,$CLJS.AK,$CLJS.FK,$CLJS.XK,$CLJS.Qu,$CLJS.Zu],!0)),G2=null,H2=0,I2=0;;)if(I2<H2){var oqa=G2.$(null,I2);$CLJS.mV(oqa,m2);I2+=1}else{var J2=$CLJS.A(F2);
if(J2){var K2=J2;if($CLJS.re(K2)){var L2=$CLJS.$c(K2),pqa=$CLJS.ad(K2),qqa=L2,rqa=$CLJS.E(L2);F2=pqa;G2=qqa;H2=rqa}else{var sqa=$CLJS.C(K2);$CLJS.mV(sqa,m2);F2=$CLJS.D(K2);G2=null;H2=0}I2=0}else break}$CLJS.h2.o(null,l2,function(a){return i2(a)});$CLJS.h2.o(null,$CLJS.yG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Im.h(e2(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.h2.g(b)],null)})),a)});
$CLJS.h2.o(null,$CLJS.NP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=g2(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NP,d2(o2,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.h2.o(null,$CLJS.wG,function(a){return $CLJS.Mm.h($CLJS.h2,a)});
$CLJS.h2.o(null,$CLJS.EQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,$CLJS.h2.g(a),g2(b)],null)});
$CLJS.h2.o(null,$CLJS.ol,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=g2($CLJS.QN(b,new $CLJS.k(null,3,[$CLJS.CG,$CLJS.sJ,$CLJS.Hl,$CLJS.CP,$CLJS.vL,$CLJS.gS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,a,b],null)});
$CLJS.h2.o(null,$CLJS.aZ,function(a){var b=f2(a);a=0==$CLJS.wP.g(a).lastIndexOf("__join",0)?$CLJS.Am.h(b,$CLJS.wP):b;return $CLJS.cn.l($CLJS.G([$CLJS.Ju($CLJS.Am.l(a,$CLJS.fZ,$CLJS.G([$CLJS.iZ])),$CLJS.h2),k2($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.iZ],null)),$CLJS.iZ,$CLJS.aS),j2(a)]))});
$CLJS.h2.o(null,$CLJS.AZ,function(a){var b=o2;o2=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.NP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.u0.j(c,d,$CLJS.h2)},k2($CLJS.u0.j($CLJS.u0.j(Upa(f2(a)),$CLJS.NP,function(c){return $CLJS.Mm.h(i2,c)}),$CLJS.PQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.h2.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Y_(v),$CLJS.F.h($CLJS.ol,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.h2.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Y_(q),$CLJS.F.h($CLJS.ol,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.LZ,$CLJS.JO),$CLJS.Bm.l(b2,$CLJS.NP,$CLJS.G([$CLJS.LZ,$CLJS.PQ])))}finally{o2=b}});$CLJS.h2.o(null,$CLJS.GZ,function(a){return $CLJS.Ju(f2(a),$CLJS.h2)});
$CLJS.h2.o(null,$CLJS.KZ,function(a){var b=f2(a),c=$CLJS.OO.g(b),d=j2(b),e=$CLJS.F.h($CLJS.uz.g($CLJS.$d($CLJS.fZ.g(a))),$CLJS.GZ)?$CLJS.UQ:$CLJS.vO;a=$CLJS.cn.l;b=$CLJS.Ju($CLJS.Am.l(b,$CLJS.fZ,$CLJS.G([$CLJS.OO])),$CLJS.h2);d=$CLJS.Pf([$CLJS.Uk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.OO,c):d;return a.call($CLJS.cn,$CLJS.G([b,c]))});